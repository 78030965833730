<template>
  <div class="about">
    <div class="log_box_main">
      <el-row class="log_box1">
        <el-col :span="24" class="dq"> 会员登录 </el-col>
      </el-row>

      <div v-if="$width > 767">
        <el-row class="formrow" v-for="(item, k) of form" :key="'k' + k">
          <el-col :span="4" :offset="1" class="formname">{{
            item.label
          }}</el-col>
          <el-col :span="14" v-if="item.label != '*验证码'">
            <el-input
              :type="item.type"
              class="inner"
              v-model="item.value"
              :placeholder="item.placeholder"
            ></el-input>
          </el-col>

          <el-col :span="8" v-if="item.label == '*验证码'">
            <el-input
              class="inner"
              v-model="item.value"
              :placeholder="item.placeholder"
            ></el-input>
          </el-col>
          <el-col :span="6" v-if="item.label == '*验证码'">
            <img
              @click="getCapcha"
              height="40px"
              style="float: right"
              :src="'data:image/png;base64,' + capcha.img"
            />
          </el-col>
        </el-row>

        <el-row class="formrow">
          <el-col :span="18" :offset="3" class="btn" @click.native="login">
            登 录
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" :offset="3" style="color: white">找回密码</el-col>
          <a href="/regis">
            <el-col
              :span="4"
              :offset="10"
              style="text-align: right; color: white; cursor: pointer"
            >
              立即注册
            </el-col>
          </a>
        </el-row>
      </div>

      <div v-if="$width < 767">
        <el-row class="formrow" v-for="(item, k) of form" :key="'k' + k">
          <el-col :span="6" class="formname">{{ item.label }}</el-col>
          <el-col :span="16">
            <el-input
              class="inner"
              v-model="item.value"
              :placeholder="item.placeholder"
            ></el-input>
          </el-col>
        </el-row>

        <el-row class="formrow">
          <el-col :span="18" :offset="3" class="btn" @click.native="login">
            登 录
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" :offset="3" style="color: white">找回密码</el-col>
          <el-col
            :span="4"
            :offset="10"
            style="text-align: right; color: white; cursor: pointer"
            >立即注册</el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "trheader",
  data() {
    return {
      action: false,
      form: [
        {
          label: "*用户名",
          placeholder: "请输入用户名",
          value: null,
          type: "text",
        },
        {
          label: "*密码",
          placeholder: "请输入密码",
          value: null,
          type: "password",
        },
        {
          label: "*验证码",
          placeholder: "请输入验证码",
          value: null,
          type: "number",
        },
      ],
      capcha: {},
    };
  },
  components: {},
  methods: {
    getCapcha() {
      api.captchaImage().then((res) => {
        if (res.code == 200) {
          this.capcha = res;
        }
      });
    },
    login() {
      let loginForm = {
        password: this.form[1].value,
        username: this.form[0].value,
        code: this.form[2].value,
        uuid: this.capcha.uuid,
      };
      if (!loginForm.username) {
        this.$message.error("请输入用户名");
        return;
      }
      if (!loginForm.password) {
        this.$message.error("请输入密码");
        return;
      }
      if (!loginForm.code) {
        this.$message.error("请输入验证码");
        return;
      }
      api.login(loginForm).then((res) => {
        if (res.code == 500 && res.msg == "验证码已失效") {
          this.getCapcha();
          return;
        }
        if (res.code == 200 && res.msg == "操作成功") {
          this.$message.success("登录成功");
          localStorage.setItem("tianruntoken", res.token);
          setTimeout(() => {
            location.href = "/user";
          }, 1500);
        }
      });
    },
  },
  mounted() {
    this.form[0].value = null;
    this.form[1].value = null;
    this.getCapcha();
  },
};
</script>
<style scoped>
.about {
  background: url("/images/logbg.jpeg");
}
.log_box_main {
  width: 100%;
  max-width: 600px;
  padding: 20px 0px;
  margin: 0px auto;
}
.log_box1 {
  font-size: 24px;
  color: #fff;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}
.btn {
  cursor: pointer;
}
.log_box1 .dq {
  color: #ff6d21;
}
.formrow {
  margin-bottom: 25px;
}
.formrow .btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #ec6526;
  color: #fff;
  font-size: 18px;
}
.formname {
  color: white;
  height: 40px;
  line-height: 40px;
  text-align: right;
  padding-right: 20px;
}
.inner {
  opacity: 0.7;
  /* background: #f4f4f4; */
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .formrow .btn {
    height: 40px;
    line-height: 40px;
  }
}
</style>
